import React from "react"
import { Helmet } from "react-helmet"
import { Layout } from "../components"
import { Wrapper } from "./obchodni-podminky"

const ZasadyOU: React.FC = () => {
  return (
    <Layout>
      <Helmet>
        <title>Zpracování osobních údajů - Analytics Academy</title>
        <meta name="description" content="" />

        <script>
          {`var script = document.createElement("script"); script.src =
        "https://consent.cookiebot.com/769d414f-0a95-4a56-895d-2752f4a9c3b3/cd.js";
        script.async = "true";
        document.getElementById('CookieDeclarationTag').appendChild(script);`}
        </script>
      </Helmet>
      <Wrapper>
        <h3>I. Úvodní ustanovení</h3>
        <ol>
          <li>
            Správcem vašich osobních údajů jsme my, společnost igloonet, s.r.o.,
            se sídlem Karásek 2137/5, Řečkovice, 621 00 Brno, IČ IČ 27713482,
            zapsaná v obchodním rejstříku vedeném Krajským soudem v Brně, sp.
            zn. C 53913 (dále jen „<strong>správce</strong>“).
          </li>
          <li>
            Dbáme ochrany Vašeho soukromí a Vašich osobních údajů. Vytvořili
            jsme pro Vás proto tyto Zásady ochrany osobních údajů, kde naleznete
            odpovědi na nejdůležitější otázky, které se Vašich osobních údajů
            týkají (dále jen „<strong>Zásady</strong>“).
          </li>
          <li>
            Osobními údaji se rozumí veškeré informace o identifikované nebo
            identifikovatelné fyzické osobě; identifikovatelnou fyzickou osobou
            je fyzická osoba, kterou lze přímo či nepřímo identifikovat, zejména
            odkazem na určitý identifikátor, například jméno, identifikační
            číslo, lokační údaje, síťový identifikátor nebo na jeden či více
            zvláštních prvků fyzické, fyziologické, genetické, psychické,
            ekonomické, kulturní nebo společenské identity této fyzické osoby.
          </li>
          <li>
            Berete přitom na vědomí, že projevením nezávazného zájmu o účast
            Analytics Academy vyplněním formuláře, přihlášením se k odběru
            newsletteru nebo prohlížením webových stránek dochází ke zpracování
            osobních údajů námi nebo třetími osobami, které jsme pověřili
            zpracováním; vždy však v souladu s těmito Zásadami.
          </li>
          <li>
            Dále berete na vědomí, že jsme oprávněni tyto Zásady jednostranně
            změnit. Novou verzi Zásad ochrany osobních údajů zveřejníme na svých
            internetových stránkách <a href="/">analytics-academy.cz</a> a
            zároveň Vám zašleme novou verzi těchto podmínek na e-mailovou
            adresu, kterou jste nám poskytl/a.
          </li>
          <li>
            Pověřence pro ochranu osobních údajů jsme nejmenovali. V případě
            jakýchkoli dotazů nebo při uplatnění Vašich práv ve smyslu čl. VI.
            těchto Zásad nás můžete kontaktovat přímo na e-mailové adrese{" "}
            <a href="mailto:info@analytics-academy.cz">
              info@analytics-academy.cz
            </a>
            .
          </li>
        </ol>
        <h3>II. Zdroje a kategorie zpracovávaných údajů</h3>
        <ol>
          <li>
            Zpracováváme zejména osobní údaje, které jste nám přímo poskytl/a, a
            to především při vyplnění formuláře jehož odesláním projevujete
            nezávazně zájem o Analytics Academy, a dále také při realizaci Vaší
            objednávky a Vaší následné účasti v Analytics Academy.
          </li>
          <li>
            Rozsah identifikačních a kontaktních údajů potřebných pro realizaci
            objednávky a následnou účast v Analytics Academy odpovídá rozsahu
            osobních údajů, které vyplňujete do formuláře na adrese{" "}
            <a href="/">analytics-academy.cz</a>. V některých případech však
            může být potřeba vyžádat další identifikační a kontaktní údaje. Tyto
            údaje vždy zpracováváme z titulu{" "}
            <strong>plnění smluvní povinnosti</strong>, a to včetně
            předsmluvního jednání směřujícího k uzavření smlouvy.
          </li>
          <li>
            V rámci právního titulu <strong>oprávněného zájmu</strong> pak
            můžeme zpracovávat také údaje potřebné pro zkvalitnění našich
            webových stránek a Analytics Academy, a to včetně prevence zneužití
            informací poskytnutých v rámci Analytics Academy, jakož i vedení
            statistik a analýz pro další optimalizaci dosahu Analytics Academy,
            její propagace, průzkumů a zasílání speciálních nabídek na základě
            Vašeho zájmu.
          </li>
          <li>
            Mimo shora uvedené pak zpracováváme také Vaši e-mailovou adresu v
            případě, že jste se přihlásil/a k odběru našeho newsletteru, a dále
            časový záznam, kdy k <strong>souhlasu</strong> došlo.
          </li>
          <li>
            Automaticky zaznamenáváme také technické informace o Vašem zařízení
            a prohlížeči, zejména IP adresy, atributy software a hardware a
            časové informace o projevení zájmu o Analytics Academy, a to zejména
            za účelem fungování našich webových stránek a prevenci útoku, a to
            za účelem{" "}
            <strong>plnění smlouvy a našeho i Vašeho oprávněného zájmu</strong>.
          </li>
          <li>
            Zároveň používáme nástroje, prostřednictvím kterých dochází k
            ukládání cookies (využíváme také pixely) prostřednictvím Vašeho
            webového prohlížeče. Vždy ukládáme technické cookies (které jsou
            nezbytné pro fungování našeho webu) a projevíte-li nám{" "}
            <strong>souhlas</strong> prostřednictvím našeho cookiebanner od
            Cookiebot také další cookies, které pracují s daty vzniklými při
            procházení stránek webového rozhraní generovaná pomocí statistických
            a analytických nástrojů. Z těchto nástrojů používáme zejména Google
            Analytics, Google Ads, Facebook, LinkedIn, OneSignal, Twitter, a
            Albacross. Data z cookies a pixelů ukládáme maximálně po dobu 365
            dní a můžeme je sdílet se společnostmi, které jednotlivé nástroje
            vyvinuly. Pomocí těchto online nástrojů však nesbíráme vaše osobní
            údaje, jako jsou např. jméno, příjmení, adresa, e-mail, ani telefon.
            Záznam pro cílení těchto nástrojů můžete odstranit po vymazání
            souborů cookie ve vašem prohlížeči.
          </li>
          <li>
            Vaše osobní údaje uchováváme po dobu:
            <ul>
              <li>
                nezbytnou k výkonu práv a povinností vyplývajících ze smluvního
                vztahu a/nebo jednání předcházejícímu uzavření smluvního vztahu
                mezi Vámi a námi a dále z důvodu případného uplatňování nároků z
                těchto smluvních vztahů (maximálně po dobu 10 let od ukončení
                smluvního vztahu).
              </li>
              <li>
                než je odvolán souhlas se zpracováním osobních údajů v případě,
                že jste nám takový souhlas udělili (newsletter, promoakce
                apod.), nejdéle však po dobu 10 let, a jsou-li osobní údaje
                zpracovávány na základě souhlasu.
              </li>
            </ul>
          </li>
          <li>
            Po uplynutí doby uchovávání osobních údajů osobní údaje vymažeme.
          </li>
          <li>
            Berete přitom na vědomí, že v případě, kdy nám v průběhu odeslaného
            formuláře, sjednávání podmínek smlouvy, účasti na Analytics Academy,
            případně kdykoli v rámci a/nebo pro účely výukového obsahu sdělíte
            osobní údaje třetích osob,{" "}
            <strong>činíte tak z pozice správce osobních údajů</strong> a
            odpovídáte za to, že byly tyto obdrženy, zpracovávány a sděleny v
            souladu s požadavky kladenými GDPR.
          </li>
        </ol>
        <div id="CookieDeclarationTag" />
        <h3>III. Zákonný důvod a účel zpracování osobních údajů</h3>
        <ol>
          <li>
            Zákonným důvodem zpracování osobních údajů je tak s ohledem na shora
            uvedené vždy pouze:
            <ul>
              <li>
                plnění smlouvy mezi Vámi a námi podle čl. 6 odst. 1 písm. b)
                GDPR, pod nějž spadá rovněž komunikace s Vámi před uzavřením
                smlouvy,
              </li>
              <li>
                oprávněný zájem na poskytování informací o Analytics Academy
                (zejména pro zasílání obchodních sdělení a newsletterů
                týkajících se Vaší účasti v Analytics Academy) podle čl. 6 odst.
                1 písm. f) GDPR,
              </li>
              <li>
                oprávněný zájem spočívající v zajištění fungování webových
                stránek, prevenci útoků a dalších zásahů do integrity systémů a
                práv Vás jakožto subjektu údajů,
              </li>
              <li>
                Váš souhlas se zpracováním pro účely poskytování přímého
                marketingu (zejména pro zasílání obchodních sdělení a
                newsletterů) podle čl. 6 odst. 1 písm. a) GDPR ve spojení s § 7
                odst. 2 zákona č. 480/2004 Sb., o některých službách informační
                společnosti.
              </li>
            </ul>
          </li>
          <li>
            Účelem zpracování osobních údajů je pak zejména:
            <ul>
              <li>
                administrativa spojená s organizací a výběrem vhodných účastníků
                Analytics Academy, zpracování podkladů pro učinění závazné
                objednávky a výkon práv a povinností vyplývajících ze smluvního
                vztahu mezi Vámi a námi; bez těchto údajů bychom smlouvu nemohli
                uzavřít ani plnit naše povinnosti ze smlouvy,
              </li>
              <li>
                informování o novinkách a průběhu Analytics Academy, vyřizování
                dotazů účastníků, informování o organizačních a technických
                záležitostech souvisejících s Analytics Academy, a případně také
                zasílání obchodních sdělení a činění dalších marketingových
                aktivit,
              </li>
              <li>
                statistické a analytické zpracování návštěvnosti a systémových
                informací, a ochrana integrity našich systémů a serverů.
              </li>
            </ul>
          </li>
          <li>
            Ze strany správce nedochází k automatickému individuálnímu
            rozhodování ve smyslu čl. 22 GDPR.
          </li>
        </ol>
        <h3>IV. Příjemci osobních údajů</h3>
        <ol>
          <li>
            Při poskytování služeb v rámci Analytics Academy, tedy při
            zpracování Vašich osobních údajů, využíváme služeb třetích stran.
            Příjemci osobních údajů jsou pouze důvěryhodné osoby:
            <ul>
              <li>
                podílející se na dodání služeb (zejména lektoři a mentoři) a
                realizaci plateb na základě smlouvy,
              </li>
              <li>zajišťující provoz webových stránek a související služby,</li>
              <li>zajišťující marketingové služby.</li>
              Souhlasem s těmito Zásadami nám udělujete souhlas zapojit do
              zpracování důvěryhodné třetí strany, které zpracovávají osobní
              údaje v souladu s GDPR. Všechny souhlasy, které nám udělujete,
              platí také pro tyto společnosti, které jsme pověřili zpracováním
              osobních údajů. Mimo shora uvedené strany můžeme sdílet Vaše údaje
              také s lektory kurzů a dalšími osobami a partnerskými
              společnostmi, které se podílejí na poskytování služeb v rámci
              Analytics Academy.
            </ul>
          </li>
          <li>
            Berete na vědomí, že ačkoli vynaložíme maximální úsilí, aby nedošlo
            k neoprávněnému zpracování osobních údajů třetími osobami,
            neodpovídáme Vám za újmu způsobenou neoprávněným zpracováním
            osobních údajů třetími osobami.{" "}
          </li>
          <li>
            Dále berete na vědomí, že v případě předávání údajů mohou být osobní
            údaje předávány i do zemí mimo EHS.{" "}
          </li>
        </ol>
        <h3>V. Vaše práva</h3>
        <ol>
          <li>
            Kdykoliv můžete požadovat informace o rozsahu a způsobu zpracování
            Vašich osobních údajů. Tyto informace Vám poskytneme do 30 dnů.
          </li>
          <li>
            Dále můžete požadovat také opravu svých osobních údajů, případně
            podat námitku proti zpracování osobních údajů na základě oprávněného
            zájmu a/nebo požádat o jejich výmaz.{" "}
          </li>
          <li>
            Veškerá práva, včetně práva odvolat souhlas se zpracováním osobních
            údajů, můžete uplatnit e-mailem na adrese{" "}
            <a href="mailto:info@analytics-academy.cz">
              info@analytics-academy.cz
            </a>
            . Pokud nebudete chtít dostávat e-maily od nás nebo našich partnerů,
            můžete se z odběru vždy odhlásit také kliknutím v zápatí každého
            e-mailu. To bohužel není možné u e-mailů, které jsou nezbytné,
            abyste obdrželi (např. informování o změně v obchodních podmínkách,
            změna našich kontaktních údajů apod.).
          </li>
          <li>
            V případě, že jste přesvědčeni, že Vaše údaje nezpracováváme v
            souladu právními předpisy, máte také právo obrátit se přímo na Úřad
            pro ochranu osobních údajů (www.uoou.cz).
          </li>
        </ol>
        <h3>VI. Další ustanovení o ochraně osobních údajů</h3>
        <ol>
          <li>
            Nezpracováváme osobní údaje dětí ani zvláštní kategorie osobních
            údajů, tzv. citlivé osobní údaje, ve smyslu čl. 9 GDPR.
          </li>
          <li>
            E-maily, které Vám budeme zasílat, nepovažujeme my ani vy za
            nevyžádaná obchodní sdělení ve smyslu zákona č. 40/1995, o reklamě,
            a zákona č. 480/2004, o některých službách informační společnosti.
          </li>
          <li>
            Potvrzujete nám, že poskytnuté osobní údaje jsou pravdivé, přesné a
            že se vztahují výhradně k Vaší osobě nebo že jste vložili údaje,
            jejichž užitím nedošlo k zásahu do práv třetích osob. Vždy nás
            prosím vyrozumějte o změnách v osobních údajích, aby byly
            zpracovávány pouze aktuální a úplné údaje, a to na naši žádost nebo
            i bez žádosti.
          </li>
        </ol>
        <h3>VII. Závěrečná ustanovení</h3>
        <ol>
          <li>
            Veškeré soukromoprávní právní vztahy vznikající na základě nebo v
            souvislosti se zpracováváním osobních údajů se řídí právním řádem
            České republiky, a to bez ohledu na to, odkud byl přístup k nim
            realizován. K řešení případných sporů vzniklých v souvislosti s
            ochranou soukromí mezi uživatelem a námi jsou příslušné české soudy,
            které budou aplikovat české právo.
          </li>
        </ol>
        Tyto Zásady nabývají účinnosti 29.6.2022
      </Wrapper>
    </Layout>
  )
}

export default ZasadyOU
